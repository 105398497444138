import { DishActionTypes } from './constants';

const INIT_STATE = {
    dishes: [],
    dish: {},
    loading: true,
};

type DishModel = {
    id: number;
    title: string;
    description: string;
    price: number;
    weight: number | null;
    size: number;
    photo: any;
    category: any;
    nutrient: any;
    tags: any;
    ingredient: any;
    createdAt: string;
    updatedAt: string;
};

type DishActionType = {
    type:
        | DishActionTypes.API_RESPONSE_SUCCESS
        | DishActionTypes.API_RESPONSE_ERROR
        | DishActionTypes.GET_DISHES
        | DishActionTypes.GET_DISH;
    payload: {
        actionType?: string;
        data?: [DishModel];
        error?: string;
    };
};

type State = {
    dishes?: [DishModel] | {};
    loading?: boolean;
    value?: boolean;
};

const Dishes = (state: State = INIT_STATE, action: DishActionType) => {
    switch (action.type) {
        case DishActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DishActionTypes.GET_DISHES: {
                    return {
                        ...state,
                        dishes: action.payload.data,
                        loading: false,
                        error: null,
                    };
                }
                case DishActionTypes.GET_DISH: {
                    return {
                        ...state,
                        dish: action.payload.data,
                        loading: false,
                        error: null,
                    };
                }
                default:
                    return { ...state };
            }

        case DishActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DishActionTypes.GET_DISHES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DishActionTypes.GET_DISH: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DishActionTypes.GET_DISHES:
            return { ...state, loading: true };

        case DishActionTypes.GET_DISH:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Dishes;
