import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    getIngredients as getIngredientsList
} from 'helpers';
import { ingredientApiResponseError, ingredientApiResponseSuccess } from './actions';
import { IngredientActionTypes } from './constants';

type IngredientData = {
    payload: {
        page: number;
    };
    type: string;
};

const api = new APICore();

/**
 * Get places
 * @param {*} payload - username and password
 */
function* getIngredients({ payload: { page }, type }: IngredientData): SagaIterator {
    try {
        const response = yield call(getIngredientsList, { page });
        const data = response.data;
        yield put(ingredientApiResponseSuccess(IngredientActionTypes.GET_INGREDIENTS, data));
    } catch (error: any) {
        yield put(ingredientApiResponseError(IngredientActionTypes.GET_INGREDIENTS, error));
        setAuthorization(null);
    }
}

export function* watchGetIngredients() {
    yield takeEvery(IngredientActionTypes.GET_INGREDIENTS, getIngredients);
}

function* ingredientsSaga() {
    yield all([fork(watchGetIngredients)]);
}

export default ingredientsSaga;
