import { CategoryActionTypes } from './constants';

const INIT_STATE = {
    categories: [],
    category: {},
    loading: true,
};

type CategoryModel = {
    id: number;
    title: string;
    description: string;
    startTime: string;
    endTime: string;
    createdAt: string;
    updatedAt: string;
};

type CategoryActionType = {
    type:
        | CategoryActionTypes.API_RESPONSE_SUCCESS
        | CategoryActionTypes.API_RESPONSE_ERROR
        | CategoryActionTypes.GET_CATEGORY
        | CategoryActionTypes.GET_CATEGORIES;
    payload: {
        actionType?: string;
        data?: [CategoryModel];
        error?: string;
    };
};

type State = {
    categories?: [CategoryModel] | {};
    category?: CategoryModel | {};
    loading?: boolean;
    value?: boolean;
};

const Category = (state: State = INIT_STATE, action: CategoryActionType) => {
    switch (action.type) {
        case CategoryActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CategoryActionTypes.GET_CATEGORIES: {
                    return {
                        ...state,
                        categories: action.payload.data,
                        loading: false,
                    };
                }
                case CategoryActionTypes.GET_CATEGORY: {
                    return {
                        ...state,
                        category: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CategoryActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CategoryActionTypes.GET_CATEGORIES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CategoryActionTypes.GET_CATEGORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CategoryActionTypes.GET_CATEGORIES:
            return { ...state, loading: true };
        case CategoryActionTypes.GET_CATEGORY:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Category;
