import { TagActionTypes } from './constants';

export type TagActionType = {
    type:
        | TagActionTypes.API_RESPONSE_SUCCESS
        | TagActionTypes.API_RESPONSE_ERROR
        | TagActionTypes.GET_TAGS;
    payload: {} | string;
};

type Tag = {
    id: number;
    title: string;
    createdAt: string;
    updatedAt: string;
};

// common success
export const tagApiResponseSuccess = (actionType: string, data: Tag | {}): TagActionType => ({
    type: TagActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const tagApiResponseError = (actionType: string, error: string): TagActionType => ({
    type: TagActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTags = (page: number): TagActionType => ({
    type: TagActionTypes.GET_TAGS,
    payload: { page },
});
