import { DishActionTypes } from './constants';

export type DishActionType = {
    type:
        | DishActionTypes.API_RESPONSE_SUCCESS
        | DishActionTypes.API_RESPONSE_ERROR
        | DishActionTypes.GET_DISHES
        | DishActionTypes.GET_DISH;
    payload: {} | string;
};

type Dish = {
    id: number;
    title: string;
    description: string;
    price: number;
    weight: number | null;
    size: number;
    photo: any;
    category: any;
    nutrient: any;
    tags: any;
    ingredient: any;
    createdAt: string;
    updatedAt: string;
};

// common success
export const dishApiResponseSuccess = (actionType: string, data: Dish | {}): DishActionType => ({
    type: DishActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const dishApiResponseError = (actionType: string, error: string): DishActionType => ({
    type: DishActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDishes = (page: number): DishActionType => ({
    type: DishActionTypes.GET_DISHES,
    payload: { page },
});

export const getDish = (dishId: number): DishActionType => ({
    type: DishActionTypes.GET_DISH,
    payload: { dishId },
});
