import { APICore } from './apiCore';

const api = new APICore();

function getTags(params: { page: number; }) {
    const baseUrl = '/dish-tags';
    return api.get(`${baseUrl}`, params);
}

export { getTags };
