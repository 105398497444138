import { APICore } from './apiCore';

const api = new APICore();

function getCategories(params: { page: number; }) {
    const baseUrl = '/menu-categories/owner';
    return api.get(`${baseUrl}`, params);
}

function getCategory(params: { id: number; }) {
    const baseUrl = '/menu-categories/' + params.id;
    return api.get(`${baseUrl}`, params);
}

export { getCategories, getCategory };
