import { MENU_ITEMS, MENU_ITEMS_ADMIN, MENU_ITEMS_OWNER, MenuItemType } from 'appConstants';
import {useRedux} from "../hooks";

const useGetMenuItems = () => {
    const {dispatch, appSelector} = useRedux();

    const {loading, user, error} = appSelector((state) => ({
        loading: state.Auth.loading,
        user: state.Auth.user,
        error: state.Auth.error
    }));
    const userRole = user.role;
    // NOTE - You can fetch from server and return here as well
    switch (userRole) {
        case 'venue_owner': return MENU_ITEMS_OWNER;
        case 'Admin': return MENU_ITEMS_ADMIN;
        default: return MENU_ITEMS;
    }
};

const findAllParent = (menuItems: MenuItemType[], menuItem: MenuItemType): string[] => {
    let parents: string[] = [];
    const parent = findMenuItem(menuItems, menuItem['parentKey']);

    if (parent) {
        parents.push(parent['key']);
        if (parent['parentKey']) parents = [...parents, ...findAllParent(menuItems, parent)];
    }

    return parents;
};

const findMenuItem = (
    menuItems: MenuItemType[] | undefined,
    menuItemKey: MenuItemType['key'] | undefined
): MenuItemType | null => {
    if (menuItems && menuItemKey) {
        for (var i = 0; i < menuItems.length; i++) {
            if (menuItems[i].key === menuItemKey) {
                return menuItems[i];
            }
            var found = findMenuItem(menuItems[i].children, menuItemKey);
            if (found) return found;
        }
    }
    return null;
};

export { useGetMenuItems, findAllParent, findMenuItem };
