import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import placeSaga from './place/saga';
import usersSaga from './users/saga';
import categoriesSaga from './category/saga';
import dishesSaga from './dish/saga';
import tagsSaga from './tags/saga';
import ingredientsSaga from './ingredients/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(),
        placeSaga(), usersSaga(), categoriesSaga(), dishesSaga(), tagsSaga(), ingredientsSaga()]);
}
