import { UsersActionTypes } from './constants';

const INIT_STATE = {
    users: [],
    loading: true,
};

type User = {
    id: number;
    fullName: string;
    email: string;
    password: string;
    deviceUUID: string;
    role: string;
    deviceTokens: string;
    timeZone: string;
    defaultLang: string;
    isNotificationEnable: boolean;
    osType: string;
    appVersion: string;
    appleId: string;
    googleId: string;
    applySettings: string;
    createdAt: string;
    updatedAt: string;
    allergens: any[];
};

type UsersActionType = {
    type:
        | UsersActionTypes.API_RESPONSE_SUCCESS
        | UsersActionTypes.API_RESPONSE_ERROR
        | UsersActionTypes.GET_USERS;
    payload: {
        actionType?: string;
        data?: [User];
        error?: string;
    };
};

type State = {
    users?: [User] | {};
    loading?: boolean;
    value?: boolean;
};

const User = (state: State = INIT_STATE, action: UsersActionType) => {
    console.log('state', state);
    console.log('action', action);
    switch (action.type) {
        case UsersActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UsersActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UsersActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UsersActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UsersActionTypes.GET_USERS:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default User;
