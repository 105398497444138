import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    getCategories as getCategoriesList,
    getCategory as getCategoryObject
} from 'helpers';
import { categoryApiResponseError, categoryApiResponseSuccess } from './actions';
import { CategoryActionTypes } from './constants';

type CategoryData = {
    payload: {
        page: number;
        id: number;
    };
    type: string;
};

const api = new APICore();

/**
 * Get places
 * @param {*} payload - username and password
 */
function* getCategories({ payload: { page }, type }: CategoryData): SagaIterator {
    try {
        const response = yield call(getCategoriesList, { page });
        const data = response.data;
        yield put(categoryApiResponseSuccess(CategoryActionTypes.GET_CATEGORIES, data));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.GET_CATEGORIES, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

export function* watchGetCategories() {
    yield takeEvery(CategoryActionTypes.GET_CATEGORIES, getCategories);
}
function* getCategory({ payload: { id }, type }: CategoryData): SagaIterator {
    try {
        const response = yield call(getCategoryObject, { id });
        const data = response.data;
        yield put(categoryApiResponseSuccess(CategoryActionTypes.GET_CATEGORY, data));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.GET_CATEGORY, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

export function* watchGetCategory() {
    yield takeEvery(CategoryActionTypes.GET_CATEGORY, getCategory);
}

function* categoriesSaga() {
    yield all([fork(watchGetCategories), fork(watchGetCategory)]);
}

export default categoriesSaga;
