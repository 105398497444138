import { PlaceActionTypes } from './constants';

export type PlaceActionType = {
    type:
        | PlaceActionTypes.API_RESPONSE_SUCCESS
        | PlaceActionTypes.API_RESPONSE_ERROR
        | PlaceActionTypes.GET_PLACES
        | PlaceActionTypes.GET_PLACE;
    payload: {} | string;
};

type Place = {
    id: number;
    logo: string;
    title: string;
    placeSpecials: string;
    placeTypes: string;
    address: string;
    createdAt: string;
    placeCuisines: string;
};

// common success
export const placeApiResponseSuccess = (actionType: string, data: Place | {}): PlaceActionType => ({
    type: PlaceActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const placeApiResponseError = (actionType: string, error: string): PlaceActionType => ({
    type: PlaceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPlaces = (page: number): PlaceActionType => ({
    type: PlaceActionTypes.GET_PLACES,
    payload: { page },
});

export const getPlaceData = (id: number): PlaceActionType => ({
    type: PlaceActionTypes.GET_PLACE,
    payload: { id },
});
