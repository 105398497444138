import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    getUsers as getUsersList
} from 'helpers';
import { userApiResponseError, userApiResponseSuccess } from './actions';
import { UsersActionTypes } from './constants';

type UserData = {
    payload: {
        page: number;
    };
    type: string;
};

const api = new APICore();

/**
 * Get places
 * @param {*} payload - username and password
 */
function* getUsers({ payload: { page }, type }: UserData): SagaIterator {
    try {
        const response = yield call(getUsersList, { page });
        const user = response.data;
        yield put(userApiResponseSuccess(UsersActionTypes.GET_USERS, user));
    } catch (error: any) {
        yield put(userApiResponseError(UsersActionTypes.GET_USERS, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

export function* watchGetPlaces() {
    yield takeEvery(UsersActionTypes.GET_USERS, getUsers);
}

function* usersSaga() {
    yield all([fork(watchGetPlaces)]);
}

export default usersSaga;
