import { TagActionTypes } from './constants';

const INIT_STATE = {
    tags: [],
    loading: true,
};

type TagModel = {
    id: number;
    title: string;
    createdAt: string;
    updatedAt: string;
};

type TagActionType = {
    type:
        | TagActionTypes.API_RESPONSE_SUCCESS
        | TagActionTypes.API_RESPONSE_ERROR
        | TagActionTypes.GET_TAGS;
    payload: {
        actionType?: string;
        data?: [TagModel];
        error?: string;
    };
};

type State = {
    tags?: [TagModel] | {};
    loading?: boolean;
    value?: boolean;
};

const Tag = (state: State = INIT_STATE, action: TagActionType) => {

    switch (action.type) {
        case TagActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TagActionTypes.GET_TAGS: {
                    return {
                        ...state,
                        tags: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TagActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TagActionTypes.GET_TAGS: {
                    return {
                        ...state,
                        tags: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TagActionTypes.GET_TAGS:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Tag;
