import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    getPlaces as getPlaceList,
    getPlace as getPlaceData
} from 'helpers';
import { placeApiResponseError, placeApiResponseSuccess } from './actions';
import { PlaceActionTypes } from './constants';

type UserData = {
    payload: {
        page: number;
        id: number;
    };
    type: string;
};

const api = new APICore();

/**
 * Get places
 * @param {*} payload - username and password
 */
function* getPlaces({ payload: { page }, type }: UserData): SagaIterator {
    try {
        const response = yield call(getPlaceList, { page });
        const user = response.data;
        console.log(user);
        yield put(placeApiResponseSuccess(PlaceActionTypes.GET_PLACES, user));
    } catch (error: any) {
        yield put(placeApiResponseError(PlaceActionTypes.GET_PLACES, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Get place
 * @param {*} payload - id
 */
function* getPlace({ payload: { id }, type }: UserData): SagaIterator {
    try {
        const response = yield call(getPlaceData, { id });
        const user = response.data;
        console.log(user);
        yield put(placeApiResponseSuccess(PlaceActionTypes.GET_PLACE, user));
    } catch (error: any) {
        yield put(placeApiResponseError(PlaceActionTypes.GET_PLACE, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

export function* watchGetPlaces() {
    yield takeEvery(PlaceActionTypes.GET_PLACES, getPlaces);
}

export function* watchGetPlace() {
    yield takeEvery(PlaceActionTypes.GET_PLACE, getPlace);
}

function* placesSaga() {
    yield all([fork(watchGetPlaces), fork(watchGetPlace)]);
}

export default placesSaga;
