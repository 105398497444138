import { IngredientActionTypes } from './constants';

const INIT_STATE = {
    ingredients: [],
    loading: true,
};

type IngredientModel = {
    id: number;
    title: string;
    createdAt: string;
    updatedAt: string;
};

type IngredientActionType = {
    type:
        | IngredientActionTypes.API_RESPONSE_SUCCESS
        | IngredientActionTypes.API_RESPONSE_ERROR
        | IngredientActionTypes.GET_INGREDIENTS;
    payload: {
        actionType?: string;
        data?: [IngredientModel];
        error?: string;
    };
};

type State = {
    ingredients?: [IngredientModel] | {};
    loading?: boolean;
    value?: boolean;
};

const Ingredient = (state: State = INIT_STATE, action: IngredientActionType) => {

    switch (action.type) {
        case IngredientActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case IngredientActionTypes.GET_INGREDIENTS: {
                    return {
                        ...state,
                        ingredients: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IngredientActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IngredientActionTypes.GET_INGREDIENTS: {
                    return {
                        ...state,
                        ingredients: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IngredientActionTypes.GET_INGREDIENTS:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Ingredient;
