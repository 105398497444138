import { APICore } from './apiCore';

const api = new APICore();

function getPlaces(params: { page: number; }) {
    const baseUrl = '/places';
    return api.get(`${baseUrl}`, params);
}

function getPlace(params: { id: number; }) {
    const baseUrl = '/places/' + params.id;
    return api.get(`${baseUrl}`, {});
}

export { getPlaces, getPlace };
