import { CategoryActionTypes } from './constants';

export type CategoryActionType = {
    type:
        | CategoryActionTypes.API_RESPONSE_SUCCESS
        | CategoryActionTypes.API_RESPONSE_ERROR
        | CategoryActionTypes.GET_CATEGORY
        | CategoryActionTypes.GET_CATEGORIES;
    payload: {} | string;
};

type Category = {
    id: number;
    title: string;
    description: string;
    startTime: string;
    endTime: string;
    createdAt: string;
    updatedAt: string;
};

// common success
export const categoryApiResponseSuccess = (actionType: string, data: Category | {}): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const categoryApiResponseError = (actionType: string, error: string): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCategories = (page: number): CategoryActionType => ({
    type: CategoryActionTypes.GET_CATEGORIES,
    payload: { page },
});

export const getCategory = (id: number): CategoryActionType => ({
    type: CategoryActionTypes.GET_CATEGORY,
    payload: { id },
});
