import { UsersActionTypes } from './constants';
import {bool} from "yup";

export type UsersActionType = {
    type:
        | UsersActionTypes.API_RESPONSE_SUCCESS
        | UsersActionTypes.API_RESPONSE_ERROR
        | UsersActionTypes.GET_USERS;
    payload: {} | string;
};

type User = {
    id: number;
    fullName: string;
    email: string;
    password: string;
    deviceUUID: string;
    role: string;
    deviceTokens: string;
    timeZone: string;
    defaultLang: string;
    isNotificationEnable: boolean;
    osType: string;
    appVersion: string;
    appleId: string;
    googleId: string;
    applySettings: string;
    createdAt: string;
    updatedAt: string;
    allergens: any[];
};

// common success
export const userApiResponseSuccess = (actionType: string, data: User | {}): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UsersActionType => ({
    type: UsersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUsers = (page: number): UsersActionType => ({
    type: UsersActionTypes.GET_USERS,
    payload: { page },
});
