import { APICore } from './apiCore';

const api = new APICore();

function getIngredients(params: { page: number; }) {
    const baseUrl = '/ingredients';
    return api.get(`${baseUrl}`, params);
}

export { getIngredients };
