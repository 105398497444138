import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));

const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const Dashboard = React.lazy(() => import('pages/admin/dashboard'));

// tables
const BusinessesTables = React.lazy(() => import('pages/admin/businesses'));
const BusinessForm = React.lazy(() => import('pages/admin/business'));
const UsersTables = React.lazy(() => import('pages/admin/users'));

const CategoriesTables = React.lazy(() => import('pages/admin/categories'));
const CategoryForm = React.lazy(() => import('pages/admin/categories/form'));

const DishesTables = React.lazy(() => import('pages/admin/dishes'));
const DishForm = React.lazy(() => import('pages/admin/dishes/form'));

const ProfileForm = React.lazy(() => import('pages/admin/profile'));
const MessagesTables = React.lazy(() => import('pages/admin/messages'));
const OrdersTables = React.lazy(() => import('pages/admin/orders'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    let Layout = VerticalLayout;

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            path: '/',
            element: <PrivateRoute roles={'Admin venue_owner'} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={Dashboard} />,
                },
                {
                    path: 'business',
                    element: <LoadComponent component={BusinessForm} />,
                },
                {
                    path: 'businesses',
                    element: <LoadComponent component={BusinessesTables} />
                },
                {
                    path: 'business',
                    children: [
                        {
                            path: ':id',
                            element: <LoadComponent component={BusinessForm} />,
                        }
                    ]
                },
                {
                    path: 'categories',
                    element: <LoadComponent component={CategoriesTables} />,
                },
                {
                    path: 'category',
                    children: [
                        {
                            path: ':id',
                            element: <LoadComponent component={CategoryForm} />,
                        }
                    ]
                },
                {
                    path: 'dishes',
                    element: <LoadComponent component={DishesTables} />,
                },
                {
                    path: 'dish',
                    children: [
                        {
                            path: ':id',
                            element: <LoadComponent component={DishForm} />,
                        }
                    ]
                },
                {
                    path: 'users',
                    element: <LoadComponent component={UsersTables} />,
                },
                {
                    path: 'profile',
                    element: <LoadComponent component={ProfileForm} />,
                },
                {
                    path: 'orders',
                    element: <LoadComponent component={OrdersTables} />,
                },
                {
                    path: 'messages',
                    element: <LoadComponent component={MessagesTables} />,
                }
            ],
        }
    ]);
};

export { AllRoutes };
