import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    getTags as getTagsList
} from 'helpers';
import { tagApiResponseError, tagApiResponseSuccess } from './actions';
import { TagActionTypes } from './constants';

type TagData = {
    payload: {
        page: number;
    };
    type: string;
};

const api = new APICore();

/**
 * Get places
 * @param {*} payload - username and password
 */
function* getTags({ payload: { page }, type }: TagData): SagaIterator {
    try {
        const response = yield call(getTagsList, { page });
        const data = response.data;
        yield put(tagApiResponseSuccess(TagActionTypes.GET_TAGS, data));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.GET_TAGS, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

export function* watchGetTags() {
    yield takeEvery(TagActionTypes.GET_TAGS, getTags);
}

function* tagsSaga() {
    yield all([fork(watchGetTags)]);
}

export default tagsSaga;
