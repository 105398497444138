import { IngredientActionTypes } from './constants';

export type IngredientActionType = {
    type:
        | IngredientActionTypes.API_RESPONSE_SUCCESS
        | IngredientActionTypes.API_RESPONSE_ERROR
        | IngredientActionTypes.GET_INGREDIENTS;
    payload: {} | string;
};

type Ingredient = {
    id: number;
    title: string;
    createdAt: string;
    updatedAt: string;
};

// common success
export const ingredientApiResponseSuccess = (actionType: string, data: Ingredient | {}): IngredientActionType => ({
    type: IngredientActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ingredientApiResponseError = (actionType: string, error: string): IngredientActionType => ({
    type: IngredientActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getIngredients = (page: number): IngredientActionType => ({
    type: IngredientActionTypes.GET_INGREDIENTS,
    payload: { page },
});
