import { useState } from 'react';

export default function useDatePicker() {
    const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date());

    /*
     * handle date change
     */
    const onDateFromChange = (date: Date) => {
        if (date) {
            setSelectedDateFrom(date);
        }
    };
    /*
     * handle date change
     */
    const onDateToChange = (date: Date) => {
        if (date) {
            setSelectedDateTo(date);
        }
    };

    return { selectedDateFrom, onDateFromChange, selectedDateTo, onDateToChange };
}
