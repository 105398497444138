import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Place from './place/reducers';
import Users from './users/reducers';
import Categories from './category/reducers';
import Dishes from './dish/reducers';
import Tag from './tags/reducers';
import Ingredient from './ingredients/reducers';

export default combineReducers({
    Auth,
    Layout,
    Place,
    Users,
    Categories,
    Dishes,
    Tag,
    Ingredient,
});
