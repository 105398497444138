export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
];

const MENU_ITEMS_OWNER: MenuItemType[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/dashboard',
    },
    {
        key: 'business',
        label: 'Business',
        isTitle: false,
        icon: 'uil-store',
        url: '/business',
    },
    {
        key: 'menu',
        label: 'Menu',
        isTitle: false,
        icon: 'uil-clipboard',
        children: [
            {
                key: 'categories',
                label: 'Manage categories',
                isTitle: false,
                icon: 'uil-circle',
                url: '/categories',
                parentKey: 'menu',
            },
            {
                key: 'dishes',
                label: 'Manage dishes',
                isTitle: false,
                icon: 'uil-circle',
                url: '/dishes',
                parentKey: 'menu',
            },
        ],
    },
    {
        key: 'messages',
        label: 'Messages',
        isTitle: false,
        icon: 'uil-comment-alt',
        url: '/messages',
    },
    {
        key: 'orders',
        label: 'Orders',
        isTitle: false,
        icon: 'uil-calender',
        url: '/orders',
    },
    {
        key: 'users',
        label: 'Staff',
        isTitle: false,
        icon: 'uil-user',
        url: '/users',
    },
];

const MENU_ITEMS_ADMIN: MenuItemType[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/dashboard',
    },
    {
        key: 'users',
        label: 'Users',
        isTitle: false,
        icon: 'uil-user',
        url: '/users',
    },
    {
        key: 'businesses',
        label: 'Businesses',
        isTitle: false,
        icon: 'uil-store',
        url: '/businesses',
    },
];

export { MENU_ITEMS, MENU_ITEMS_ADMIN, MENU_ITEMS_OWNER };
