import { getPlaces } from 'helpers/api/places';
import { PlaceActionTypes } from './constants';


const INIT_STATE = {
    places: [],
    place: {},
    loading: true,
};

type Place = {
    id: number;
    logo: string;
    title: string;
    placeSpecials: string;
    placeTypes: string;
    address: string;
    createdAt: string;
    placeCuisines: string;
};

type PlaceActionType = {
    type:
        | PlaceActionTypes.API_RESPONSE_SUCCESS
        | PlaceActionTypes.API_RESPONSE_ERROR
        | PlaceActionTypes.GET_PLACES
        | PlaceActionTypes.GET_PLACE;
    payload: {
        actionType?: string;
        data?: [Place];
        error?: string;
    };
};

type State = {
    places?: [Place] | {};
    place?: Place | {};
    loading?: boolean;
    value?: boolean;
};

const Place = (state: State = INIT_STATE, action: PlaceActionType) => {
    console.log('state', state);
    console.log('action', action);
    switch (action.type) {
        case PlaceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PlaceActionTypes.GET_PLACES: {
                    return {
                        ...state,
                        places: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case PlaceActionTypes.GET_PLACE: {
                    return {
                        ...state,
                        place: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PlaceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PlaceActionTypes.GET_PLACES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PlaceActionTypes.GET_PLACE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PlaceActionTypes.GET_PLACES:
            return { ...state, loading: true };

        case PlaceActionTypes.GET_PLACE:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Place;
