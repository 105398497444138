import { APICore } from './apiCore';

const api = new APICore();

function getDishes(params: { page: number; }) {
    const baseUrl = '/dishes/owner';
    return api.get(`${baseUrl}`, params);
}

function getDish(params: { dishId: number; }) {
    const baseUrl = '/dishes/' + params.dishId;
    return api.get(`${baseUrl}`, params);
}

export { getDishes, getDish };
